body {
    font-family: 'Arial', sans-serif;
    background-color: #F1E6D8;
    color: #333;

}

h1 {
    color: #4417C1 !important;
    font-size: 5rem;
    text-align: center;
    margin-bottom: 1rem;
}

h2 {
    color: #333 !important;
    font-size: 5rem;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

h3 {
    font-size: 4.5rem;
    font-weight: 600;
    color: #333;
}

p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #666;
}

@media (max-width: 768px) {
    h2 {
        font-size: 3rem;
    }

    h3 {
        font-size: 2.5rem;
    }
    
}

::-webkit-scrollbar {
    width: 10px; /* Kaydırma çubuğu genişliği */
}

::-webkit-scrollbar-track {
    background: #fdfcf3; /* Şeffaf arka plan */
}

::-webkit-scrollbar-thumb {
    background: #9e9b9b; /* Kaydırma çubuğu rengi */
    border-radius: 10px; /* Kenar yuvarlama */
}

::-webkit-scrollbar-thumb:hover {
    background: #555; /* Üzerine gelindiğinde kaydırma çubuğu rengi */
}