.welcomeSection {
    background-color: #F1E6D8;
    padding: 80px 0;
    text-align: center;
}

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 10px;
}

.logo {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
    object-fit: cover;
    border: 3px solid #ff8000;
    padding: 2px;
    background-color: white;
    border-radius: 100%;
}

.title {
    font-size: 3rem;
    color: #4417C1;
    margin-bottom: 10px;
}

.subtitle {
    display: block;
    font-size: 1.8rem;
    color: #ff8000;
    margin-bottom: 10px;
    
}

.description {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 30px;
    line-height: 1.6;
}

.customFont {
    font-weight: bold;
    font-size: 4rem;
}
