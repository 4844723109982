.careerPage {
    padding: 10rem;
    text-align: center; /* Başlık ve kartı ortala */
}

.title {
    font-size: 4rem;
    color: #333 !important;
    text-align: center;
    margin: 5vh;
}

.noListings {
    display: flex;
    justify-content: center; /* Kartı yatayda ortala */
    align-items: center; /* Kartı dikeyde ortala */
}

.card {
    background-color: #fdfcf3;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    max-width: 400px; /* Kartın maksimum genişliği */
    width: 100%; /* Kartın genişliğini %100 yap */
    text-align: center; /* Kart içeriğini ortala */
}

.icon {
    font-size: 4rem; /* İkon boyutu */
    color: #4417C1; /* İkon rengi */
    margin-top: 1rem;
}

.cardTitle {
    font-size: 1.5rem;
    color: #333;
}

.cardDescription {
    font-size: 1rem;
    color: #666;
    margin-top: 2rem;
}

@media (max-width: 768px) {
    .careerPage {
        padding: 5rem; /* Reduce padding for smaller screens */
    }

    .title {
        font-size: 2.5rem; /* Smaller title size for mobile */
    }

    .card {
        padding: 1rem; /* Reduce padding inside cards */
        max-width: 90%; /* Allow cards to take more width on mobile */
    }

    .icon {
        font-size: 3rem; /* Smaller icon size for mobile */
    }

    .cardTitle {
        font-size: 1.2rem; /* Smaller card title size */
    }

    .cardDescription {
        font-size: 0.9rem; /* Smaller card description size */
    }
} 