.footer {
    background-color: #000000;
    color: white;
    padding: 100px 0 50px;
    width: 100%;
}

.container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 80px;
}

.footerContent {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    gap: 80px;
    margin-bottom: 80px;
}

.footerSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.rightSection {
    display: flex;
    gap: 80px;
}

.rightSection .footerSection {
    flex: 1;
}

.footerSection h3 {
    color: #ffffff;
    font-size: 1.5rem;
    margin-bottom: 15px;
    position: relative;
    padding-bottom: 15px;
    font-weight: 600;
}

.footerSection h3::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: #4417C1;
}

.logoLink {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
}

.logo {
    width: 150px;
    height: auto;
    transition: opacity 0.3s ease;
    padding: 0;
}

.logoLink:hover .logo {
    opacity: 0.8;
}

.description {
    color: #cccccc;
    line-height: 1.8;
    font-size: 1.1rem;
}

.footerNavTitle {
    color: #fff;
    text-decoration: underline;
    text-decoration-color: #4417C1; 
    text-decoration-thickness: 2px; 
    text-underline-offset: 4px; 
    transition: text-decoration-color 0.3s ease, text-decoration-thickness 0.3s ease;
}

.footerNav {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footerNav a {
    color: #cccccc;
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 1.1rem;
    padding: 5px 0;
}

.footerNav a:hover {
    color: #4417C1;
}

.contactTitle {
    color: #fff;
    text-decoration: underline;
    text-decoration-color: #4417C1; 
    text-decoration-thickness: 2px; 
    text-underline-offset: 4px; 
    transition: text-decoration-color 0.3s ease, text-decoration-thickness 0.3s ease;
}

.contactInfo {
    display: flex;
    flex-direction: column;
}

.contactInfo p {
    font-size: 1.1rem;
}

.contactInfo a {
    color: #cccccc;
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 1.1rem;
}

.contactInfo a:hover {
    color: #4417C1;
}

.followUsTitle {
    color: #fff;
    text-decoration: underline;
    text-decoration-color: #4417C1; 
    text-decoration-thickness: 2px; 
    text-underline-offset: 4px; 
    transition: text-decoration-color 0.3s ease, text-decoration-thickness 0.3s ease;
}

.socialLinks {
    display: flex;
    gap: 25px;
}

.socialLinks a {
    color: #cccccc;
    font-size: 2rem;
    transition: color 0.3s ease;
}

.socialLinks a:hover {
    color: #4417C1;
}

.copyright {
    text-align: center;
    padding-top: 40px;
    border-top: 1px solid #333;
    color: #cccccc;
    font-size: 1rem;
}

@media (max-width: 1024px) {
    .footerContent {
        grid-template-columns: 1fr 1fr;
    }
    
    .rightSection {
        grid-column: span 2;
        justify-content: space-around;
    }
}

@media (max-width: 768px) {
    .footerContent {
        grid-template-columns: 1fr;
        gap: 40px;
    }
    
    .rightSection {
        grid-column: span 1;
        flex-direction: column;
        gap: 40px;
    }
    
    .footer {
        padding: 60px 0 30px;
    }
    
    .container {
        padding: 0 20px;
    }
}