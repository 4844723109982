.aboutSection {
    padding-top: 8vh;
    padding-bottom: 3vh;
    background-color: #F1E6D8;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.title {
    font-size: 4rem;
    color: #333 !important;
    text-align: center;
    margin: 5vh;
}

.content {
    display: flex;
    justify-content: center;
}
.textContainer {
    max-width: fit-content;
    background-color: #fdfcf3;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.textContainer p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #333;
    margin-bottom: 30px;
}

.textContainer p:last-child {
    margin-bottom: 0;
}