.roadmapSection {
    padding: 4rem 2rem;
    background-color: #F1E6D8;
}

.timelineContainer {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 0;
}

.timelineContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 40px;
    width: 4px;
    height: 100%;
    background: #4417C1;
}

.timelineItem {
    padding: 1rem 0;
    position: relative;
    padding-left: 80px;
    margin-bottom: 2rem;
}

.timelineContent {
    background: #fdfcf3;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    padding: 1.5rem;
}

.timelineDot {
    position: absolute;
    width: 20px;
    height: 20px;
    background: #4417C1;
    border-radius: 50%;
    left: 31px;
    top: 50%;
    transform: translateY(-50%);
}

.date {
    font-size: 1.2rem;
    font-weight: bold;
    color: #4417C1;
    margin-bottom: 0.5rem;
}

.description {
    font-size: 1rem;
    color: #555;
}

@media (min-width: 768px) {
    .timelineContainer::before {
        left: 50%;
        transform: translateX(-50%); 
    }

    .timelineDot {
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .timelineItem:nth-child(even) .timelineDot {
        left: 50%;
        right: auto;
        transform: translate(-50%, -50%);
    }

    .timelineItem {
        padding-right: calc(50% + 30px); 
        padding-left: 0;
    }

    .timelineItem:nth-child(even) {
        padding-left: calc(50% + 30px); 
        padding-right: 0;
    }

    .timelineContent {
        width: calc(100% - 30px); 
    }

    .timelineItem:nth-child(even) .timelineContent {
        margin-left: 20px; 
    }
}

