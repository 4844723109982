.problemSolutionSection {
    padding: 4rem 2rem;
    background-color: #F1E6D8;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.column {
    flex-basis: 48%;
    margin-bottom: 2rem;
}

.itemList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0;
    margin: 0;
    list-style-type: none;
    height: 100%;
}

.item {
    display: flex;
    align-items: center;
    background-color: #fdfcf3;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    padding: 1.5rem;
    flex: 1;
    transition: all 0.3s ease;
}

.item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.icon {
    font-size: 1.5rem;
    margin-right: 10px;
    color: #4417C1;
    transition: color 0.3s ease;
}

.item:hover .icon {
    color: #5a2de0;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.content p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .column {
        flex-basis: 100%;
    }
}