.businessModelSection {
    padding: 4rem 2rem;
    background-color: #F1E6D8;
}

.sectionTitle {
    font-size: 2.5rem;
    color: #4417C1;
    text-align: center;
    margin-bottom: 1rem;
}

.subTitle {
    font-size: 1.5rem;
    color: #333;
    text-align: center;
    margin-bottom: 2rem;
}

.offeringsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1000px;
    margin: 0 auto;
}

.offeringList {
    list-style-type: none;
    padding: 1.5rem;
}

.offeringList li {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    color: #666;
    text-align: left;
}

.infoCard {
    background-color: #fdfcf3;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    padding: 1.5rem;
    transition: transform 0.3s ease;
    max-width: 600px;
}

.infoCard:hover {
    transform: translateY(-5px);
}

.infoCard h3 {
    color: #4417C1;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #ff8000;
    padding-bottom: 0.5rem;
    text-align: center;
}

.infoCard ul {
    list-style-type: none;
    padding: 0;
}

.infoCard li {
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 20px;
}

.infoCard li:before {
    content: "\2022";
    color: #ff8000;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    color: #4417C1;
    font-size: 1.8rem;
}


@media (max-width: 768px) {
    .offeringsContainer {
        grid-template-columns: 1fr;
    }
}