.cofounder-card {
    background: #fdfcf3;
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
    margin: 8px;
    width: 100%;
    max-width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

}

.cofounder-card:hover {
    transform: translateY(-5px);
}

.cofounder-photo {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 12px;
}

.cofounder-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 8px 0;
    color: #333;
    text-align: center;
}

.company-logo {
    width: 35px;
    height: auto;
    margin: 8px 0;
}

.linkedin-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: opacity 0.3s ease;
    color: #999;
}

.linkedin-icon:hover {
    color: #0077b5;
}

.cofounders-container {
    display: grid;
    gap: 15px;
    padding: 15px;
    max-width: 1400px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

/* Small mobile screens */
@media (max-width: 480px) {
    .cofounders-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-template-columns: 1fr;
        padding: 10px;
    }
    
    .cofounder-card {
        padding: 12px;
        margin: 6px;
    }
    
    .cofounder-photo {
        width: 100px;
        height: 100px;
    }
    
    .cofounder-name {
        font-size: 1.1rem;
    }
}

/* Large mobile screens */
@media (min-width: 481px) and (max-width: 767px) {
    .cofounders-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
    
    .cofounder-photo {
        width: 110px;
        height: 110px;
    }
}

/* Tablet screens */
@media (min-width: 768px) and (max-width: 1023px) {
    .cofounders-container {
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
    }
}

/* Laptop screens */
@media (min-width: 1024px) and (max-width: 1439px) {
    .cofounders-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Large screens */
@media (min-width: 1440px) {
    .cofounders-container {
        grid-template-columns: repeat(5, 1fr);
    }
}
