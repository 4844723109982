.targetMarketSection {
    padding: 4rem 2rem;
    background-color: #F1E6D8;
}

.contentWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.infoCard {
    background-color: #fdfcf3;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    padding: 1.5rem;
    transition: transform 0.3s ease;
}

.infoCard:hover {
    transform: translateY(-5px);
}

.infoCard h3 {
    color: #4417C1;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #ff8000;
    padding-bottom: 0.5rem;
    text-align: center;
}

.infoCard ul {
    list-style-type: none;
    padding: 0;
}

.infoCard li {
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
    font-size: 1.2rem;
}

.infoCard li::before {
    content: '•';
    color: #ff8000;
    position: absolute;
    left: 0;
    font-size: 1.2rem;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    color: #4417C1;
    font-size: 1.8rem;
}

@media (max-width: 768px) {
    .sectionTitle {
        font-size: 2rem;
    }

    .infoCard h3 {
        font-size: 1.3rem;
    }
}

@media (max-width: 480px) {
    .sectionTitle {
        font-size: 1.8rem;
    }

    .infoCard {
        padding: 1rem;
    }
}