.inputSection {
    background: #f9fafb;
    padding: 1rem;
    margin: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
}

.inputGroup label {
    font-size: 0.9rem;
    font-weight: 600;
    color: #2d3748;
}

.inputWithIcon {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}
.inputWithIcon input {
    width: 100%;
    height: 42px;
    padding: 0.5rem 2.2rem 0.5rem 0.6rem;
    border: 1px solid #cbd5e0;
    border-radius: 8px;
    font-size: 1rem;
    background: #ffffff;
    color: #2d3748;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    -moz-appearance: textfield; /* Firefox */
    -webkit-appearance: none; /* Chrome, Safari, Edge */
    text-align: center; /* Added to center the text */
}

.inputWithIcon input:focus {
    outline: none;
    border-color: #4417C1;
    box-shadow: 0 0 0 3px rgba(68, 23, 193, 0.1);
}

.inputIcon {
    position: absolute;
    color: #718096;
    font-size: 0.9rem;
    pointer-events: none;
}

.inputIcon:first-child {
    left: 0.6rem;
}

.inputIcon:last-child {
    right: 0.6rem;
}

.addButton {
    background: #4417C1;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.2s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.addButton:hover {
    background: #3612a0;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(68, 23, 193, 0.2);
}

.addButton:active {
    transform: translateY(0);
}

@media (max-width: 768px) {
    .sectionHeader {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
}

.inputWithIcon input::-webkit-outer-spin-button,
.inputWithIcon input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}