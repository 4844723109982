* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: transparent;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(0);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1100;
    box-shadow: none;
}

.logoContainer {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 100%;
}

.logo {
    width: clamp(40px, 4vw + 10px, 60px);
    height: clamp(40px, 4vw + 10px, 60px);
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ff8000;
    padding: 2px;
    transition: transform 0.3s ease;
    cursor: pointer;
    background-color: white;
}

.logo:hover {
    transform: scale(1.05);
}

.navContent {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    padding: 0 2rem;
}

.navLinks {
    display: flex;
    gap: 2rem;
    list-style: none;
    margin: 0;
    padding: 0;
}

.navLinks li {
    color: #ff8000;
    font-size: clamp(14px, 1vw + 2px, 16px);
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 0.6rem 1rem;
    font-weight: 600;
    border-radius: 8px;
    letter-spacing: 0.3px;
}

.navLinks li a {
    color: #333;
    text-decoration: none;
    transition: all 0.2s ease;
    position: relative;
    padding: 0.2rem 0;
}

.navLinks li a:hover {
    color: #ff8000;
}

.navLinks li a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #ff8000;
    transition: width 0.2s ease;
}

.navLinks li a:hover::after {
    width: 100%;
}

.languageSwitch {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 20px;
    padding: 0.3rem 0.6rem;
    cursor: pointer;
    transition: transform 0.3s ease;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.languageSwitch:hover {
    transform: translateY(-2px);
}

.languageOption {
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
    color: #666;
    border-radius: 12px;
    transition: all 0.3s ease;
    font-weight: bold;
}

.languageOptionActive {
    background: #ff8000;
    color: white;
}

.menuButton {
    display: none;
    background: transparent;
    border: none;
    color: #333;
    font-size: 1.8rem;
    cursor: pointer;
    padding: 2px;
    position: fixed;
    right: 1.5rem;
    top: 1rem;
    z-index: 1200;
    transition: all 0.3s ease;
    border-radius: 8px;
}

.menuButton:hover {
    color: #ff8000;
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .navbar {
        padding: 0.8rem 1.5rem;
        justify-content: flex-start;
    }

    .menuButton {
        display: flex;
        color: #666;
    }

    .menuButton:hover {
        color: #ff8000;
        background-color: rgba(255, 128, 0, 0.1);
        transform: scale(1.1);
    }

    .navContent {
        position: fixed;
        top: 0;
        right: -100%;
        height: 100vh;
        width: 100%;
        background: #4417C1;
        backdrop-filter: blur(10px);
        flex-direction: column;
        padding: 5rem 2rem;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        box-shadow: -5px 0 25px rgba(0, 0, 0, 0.1);
    }

    .navContent.active {
        right: 0;
    }

    .navContent.active + .menuButton {
        color: white;
    }
    
    .navLinks {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        width: 100%;
        margin-top: 12vh;
        gap: 0.5rem;
    }

    .navLinks li {
        width: 100%;
        text-align: center;
        padding: 1rem;
        margin: 0.3rem 0;
        border-radius: 12px;
        transition: all 0.2s ease;
        border: none;
    }

    .navLinks li:hover {
        background-color: rgba(255, 128, 0, 0.1);
    }

    .navLinks li a {
        font-weight: 600;
        font-size: 2rem;
        display: block;
        width: 100%;
        color: white;
    }

    .navLinks li a::after {
        display: none;
    }

    .languageSwitch {
        margin-top: 3rem;
        padding: 0.4rem 0.8rem;
        background: rgba(255, 255, 255, 0.9);
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    }

    .languageOption {
        padding: 0.3rem 0.8rem;
        font-size: 1rem;
    }
}

.hidden {
    transform: translateY(-100%);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.visible {
    transform: translateY(0);
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}