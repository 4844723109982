.container {
  display: flex;
  min-height: 100vh;
  position: relative;
  background: #f5f7ff;
  padding-top: 60px;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 60px;
  width: 420px;
  height: calc(100vh - 60px);
  background-color: #ffffff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
  overflow-y: auto;
  transform: translateX(-100%);
  padding: 10px;
}

.sidebar.open {
  transform: translateX(0);
}

.content {
  flex: 1;
  padding: 2rem;
  margin-left: 0;
  transition: margin-left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 1rem;
}

.sidebar.open + .content {
  margin-left: 420px;
}

.sidebarToggle {
  position: fixed;
  left: -35px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  z-index: 1001;
  background-color: #4417C1;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 400;
  width: 100px;
  height: 25px;
  border-radius: 16px;
  letter-spacing: 0.5px;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  text-transform: uppercase;
}

.sidebar.open ~ .sidebarToggle {
  left: 385px;
}

.sidebarToggle:hover {
  background-color: #4417C1;
  transform: translateY(-50%) rotate(90deg);
}

@media (max-width: 768px) {
  .container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow-y: auto;
  }

  .content {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow-y: auto;
  }

  .sidebar.open + .content {
    margin-left: 0;
    opacity: 0.3;
    pointer-events: none;
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .sidebar {
    width: 100%;
    max-width: 100%;
    padding: 10px;
    overflow-y: auto;
    top: 0;
    height: 100vh;
    position: fixed;
    left: 0;
    transform: translateX(-100%);
  }

  .sidebarToggle {
    position: fixed;
    left: auto;
    right: 16px;
    bottom: 24px;
    top: auto;
    transform: none;
    width: 120px;
    height: 40px;
    border-radius: 20px;
    background-color: #4417C1;
    box-shadow: 0 4px 16px rgba(68, 23, 193, 0.25);
    font-size: 0.85rem;
    z-index: 1002;
  }

  .sidebar.open ~ .sidebarToggle {
    position: fixed;
    left: auto;
    right: 16px;
    transform: none;
  }
}

.sidebarToggle[aria-label] {
  position: fixed;
} 