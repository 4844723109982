.timingSection {
    padding: 4rem 2rem;
    background-color: #F1E6D8;
    color: black;
}

.timingContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.timingItem {
    background-color: #fdfcf3;
    border-radius: 10px;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;
    flex: 1 1 300px;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.timingItem:hover {
    transform: translateY(-5px);
}

.timingIcon {
    font-size: 1.5rem;
    margin-right: 1rem;
    color: #4417C1;
}

.timingItem p {
    font-size: 1.2rem;
    margin: 0;
}

@media (max-width: 768px) {
    .timingSection {
        padding: 3rem 1rem;
    }

    .sectionTitle {
        font-size: 2rem;
    }

    .timingItem {
        flex: 1 1 100%;
    }
}

@media (max-width: 480px) {
    .sectionTitle {
        font-size: 1.8rem;
    }

    .timingItem {
        padding: 1rem;
    }

    .timingItem p {
        font-size: 1rem;
    }
}