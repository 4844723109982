.graphContainer {
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    margin-bottom: 1.5rem;
    height: auto;
    min-height: 450px;
    width: 100%;
    transition: all 0.3s ease;
}

.sectionTitle {
    color: #4417C1;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
}

@media (max-width: 768px) {
    .graphContainer {
        padding: 0.75rem;
        min-height: 300px;
        border-radius: 8px;
        margin-bottom: 1rem;
        width: 100%;
        overflow-x: hidden;
    }

    .sectionTitle {
        font-size: 1rem;
        margin-bottom: 0.75rem;
    }

    .recharts-legend-item {
        font-size: 10px;
    }
    
    .recharts-wrapper {
        width: 100% !important;
        height: auto !important;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .graphContainer {
        padding: 1rem;
        min-height: 375px;
    }

    .recharts-legend-item {
        font-size: 11px;
    }
}

.recharts-legend-item {
    font-size: 12px;
} 