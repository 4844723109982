/* Campaign Container */
.campaignsContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

/* Campaign Card */
.campaignCard {
    position: relative;
    background: #ffffff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.05);
}

/* Campaign Name Input */
.campaignName {
    flex: 1;
    font-size: 1.2rem;
    font-weight: 600;
    height: 42px;
    padding: 0 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    background: white;
    transition: all 0.2s ease;
    width: 100%;
    margin-bottom: 1rem;
}

.campaignName:focus {
    outline: none;
    border-color: #4417C1;
    box-shadow: 0 0 0 3px rgba(68, 23, 193, 0.1);
}

/* Campaign Grid Layout */
.campaignGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

/* Input Group Styles */
.inputGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
}

.inputGroup label {
    font-size: 0.9rem;
    color: #4a5568;
    font-weight: 500;
}

/* Input with Unit/Icon */
.inputWithIcon {
    position: relative;
    display: flex;
    align-items: center;
}

.inputWithIcon input {
    width: 100%;
    height: 42px;
    padding: 0.5rem 1rem; 
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 1rem;
    background: white;
    -moz-appearance: textfield;
    -webkit-appearance: none;
}

.inputWithIcon input::-webkit-outer-spin-button,
.inputWithIcon input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Unit/Icon Positioning */
.inputIcon {
    position: absolute;
    right: 0.5rem;
    color: #718096;
    font-size: 0.8rem;
    pointer-events: none;
    background: #f8f9fa;
    padding: 0 0.25rem;
    border-radius: 4px;
}

/* Currency Input Specific */
.inputWithIcon.currency input {
    padding-left: 2rem;
}

.inputWithIcon.currency .inputIcon.currency {
    left: 1rem;
}

/* Metrics Section */
.campaignMetrics {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.metric {
    background: none;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.metric span {
    color: #666;
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.metric strong {
    color: #4417C1;
    font-size: 1.4rem;
    font-weight: 600;
    margin-left: 0.5rem;
}

/* Add icon styles if needed */
.metricIcon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
    color: #4417C1;
}

/* Remove Button */
.removeButton {
    background: none;
    border: none;
    color: #f70000;
    font-size: 1.25rem;
    cursor: pointer;
    padding: 0.4rem;
    border-radius: 16%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0%;
    right: 0px;
}

.removeButton:hover {
    background-color: #f70000;
    color: white;
}

/* Campaign Header */
.campaignHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

/* Timeline Inputs */
.timelineInputs {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

@media (max-width: 768px) {
    .campaignCard {
        padding: 1.5rem;
    }
    
    .campaignGrid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .timelineInputs {
        grid-template-columns: 1fr;
    }
    
    .campaignMetrics {
        grid-template-columns: 1fr;
        padding: 1rem;
    }
}