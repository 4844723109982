.notFound {
    text-align: center;
    padding: 50px;
}

.notFound h1 {
    margin-top: 15vh;
    font-size: 6rem;
    color: #4417C1;
}

.notFound p {
    font-size: 1.5rem;
    color: #666;
}

.notFound a {
    color: #ff8000;
    text-decoration: none;
    font-weight: bold;
}